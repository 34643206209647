// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s9LI4{position:relative}.s9LI4:before{aspect-ratio:1;background-color:#f1f1f1;content:\"\";height:auto;position:absolute;width:100%}.lCZ99{background-color:#e0e0e0;border-radius:1px;box-shadow:0 3px 2px 0 rgba(0,0,0,.1),0 4px 8px 0 rgba(0,0,0,.05);height:7.33%!important;inset:98% 25% -4px!important;position:absolute;width:50%!important}", "",{"version":3,"sources":["webpack://./src/components/HomeAlbum/homealbum.module.css"],"names":[],"mappings":"AAAA,OACI,iBACJ,CAEA,cAII,cAAe,CAEf,wBAAoC,CALpC,UAAW,CACX,WAAY,CAGZ,iBAAkB,CAFlB,UAIJ,CAEA,OAKI,wBAAoC,CACpC,iBAAkB,CAClB,iEAAkH,CALlH,sBAAwB,CAExB,4BAA8B,CAH9B,iBAAkB,CAElB,mBAKF","sourcesContent":[".boxart {\n    position: relative;\n}\n\n.boxart::before {\n    content: \"\";\n    height: auto;\n    width: 100%;\n    aspect-ratio: 1;\n    position: absolute;\n    background-color: rgb(241, 241, 241);\n}\n\n.album_support {\n    position: absolute;\n    height: 7.33% !important;\n    width: 50% !important;\n    inset: 98% 25% -4px !important;\n    background-color: rgb(224, 224, 224);\n    border-radius: 1px;\n    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.10000000149011612), 0px 4px 8px 0px rgba(0, 0, 0, 0.05000000074505806);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxart": "s9LI4",
	"album_support": "lCZ99"
};
export default ___CSS_LOADER_EXPORT___;
